import React, {
	useCallback, useEffect, useState, Fragment
} from "react";
import produce from "immer";
import useAuth from "hooks/useAuth";
import Header from "components/pageElements/Header";
import Footer from "components/pageElements/Footer";
import Page from "components/layout/Page";
import useDialog from "hooks/useDialog";
import { infoDialog } from "components/dialogs/AlertDialog";
import useApi, { API_POST, API_PUT } from "hooks/useApi";
import { navigate } from "gatsby";

const CreateEditUser = ({ user: existingUser, inModal = false, onComplete }) => {
	const isEditing = !!existingUser;
	const [editedUser, setEditedUser] = useState(() => {
		if (existingUser) {
			return produce(existingUser, (draft) => {
				draft.projects = draft.projects.filter((p) => !!p && p instanceof Object);
			});
		}

		return {};
	});
	const { user, isLoggedIn } = useAuth();
	const api = useApi();
	const [projectsList, setProjectsList] = useState();
	const { presentDialog } = useDialog();

	const {
		email, title, firstName, lastName, client, projects, role
	} = editedUser;

	const submitForm = useCallback((ev) => {
		ev.preventDefault();
		api({
			method: isEditing ? API_PUT : API_POST,
			path: "/users",
			data: editedUser
		})
			.then(() => {
				presentDialog(infoDialog(`User ${isEditing ? "Updated" : "Created"}`, `User ${isEditing ? "Updated" : "Created"} Successfully`), null, () => {
					if (onComplete) {
						onComplete?.();
					}
					else {
						navigate("/client");
					}
				});
			})
			.catch((e) => {
				if (e.response) {
					const { message, code } = e.response.data;
					if (code === 11000) {
						presentDialog(infoDialog("Error", `Cannot ${isEditing ? "update" : "create"} this user: User already exists`));
					}
					else {
						presentDialog(infoDialog("Error", `Cannot ${isEditing ? "update" : "create"} this user: ${message}`));
					}
				}
				else {
					presentDialog(infoDialog("Error", `Cannot ${isEditing ? "update" : "create"} this user: Network Error`));
				}
			});
	}, [api, editedUser, isEditing, onComplete, presentDialog]);

	const changeValue = (ev) => {
		const { currentTarget: { name, value, checked } } = ev;
		setEditedUser((currUser) => produce(currUser, (draft) => {
			if (name === "projects") {
				if (!draft.projects) {
					draft.projects = [];
				}
				if (checked) {
					const pro = draft.projects.indexOf(value);
					if (pro < 0) {
						draft.projects.push(value);
					}
				}
				else {
					const ind = draft.projects.indexOf(value);
					if (ind >= 0) {
						draft.projects.splice(ind, 1);
					}
				}
			}
			else {
				draft[name] = value;
			}
		}));
	};

	useEffect(() => {
		if (isLoggedIn && !projectsList) {
			api({ path: "/projects" })
				.then((projectList) => {
					setProjectsList(projectList ?? []);
				})
				.catch((e) => {
					presentDialog(infoDialog("Error", `Cannot retreive projects: ${e}`));
				});
		}
	}, [api, isLoggedIn, presentDialog, projectsList]);

	let lastClient;
	let projectCount = 0;

	const projectChecks = projectsList?.map(({ displayName, id, client: projClient }) => {
		const header = lastClient !== projClient
			? (
				<>
					{ projectCount > 0 ? <hr /> : null }
					<h4>{projClient}</h4>
				</>
			) : null;
		projectCount = lastClient !== projClient ? projectCount + 1 : projectCount;
		lastClient = projClient;
		const proj = projects?.includes(id);
		return (
			<Fragment key={id}>
				{ header }
				<label htmlFor={id}>
					<b>{displayName}</b>
					&nbsp;&nbsp;
					<input id={id} type="checkbox" name="projects" value={id} checked={!!proj} onChange={changeValue} />
				</label>
			</Fragment>
		);
	});

	return (
		user?.role === "admin"
			? (
				<>
					<Page
						middle={(
							<>
								{!inModal ? <h1>Create new user</h1> : null }
								<form id="userForm">
									<fieldset>
										<legend>User details</legend>
										<label htmlFor="email">
											Email
											<input className="fluid" name="email" type="email" value={email} onChange={changeValue} />
										</label>
										<label htmlFor="title">
											Title
											<input className="fluid" name="title" type="text" value={title} onChange={changeValue} />
										</label>
										<label htmlFor="firstName">
											First Name
											<input className="fluid" name="firstName" type="text" value={firstName} onChange={changeValue} />
										</label>
										<label htmlFor="lastName">
											Last Name
											<input className="fluid" name="lastName" type="text" value={lastName} onChange={changeValue} />
										</label>
										<label htmlFor="client">
											Client
											<input className="fluid" name="client" type="text" value={client} onChange={changeValue} />
										</label>
										{ !isEditing
											? (
												<label htmlFor="password">
													Password
													<input className="fluid" name="password" type="password" onChange={changeValue} />
												</label>
											) : null }
									</fieldset>

									<fieldset>
										<legend>Projects</legend>
										<div className="projectList">
											{ projectChecks }
										</div>
									</fieldset>

									<fieldset>
										<legend>Roles</legend>
										<label htmlFor="role">
											User Type:
											<select name="role" defaultValue={role ?? "user"} onChange={changeValue}>
												<option value="admin">Admin</option>
												<option value="user">User</option>
											</select>
										</label>
									</fieldset>

									<button type="submit" onClick={submitForm}>Submit</button>
								</form>
							</>
						)}
					/>
					{ !inModal ? (
						<>
							<Header />
							<Footer />
						</>
					) : null }
				</>
			)
			: (
				<>
					<div>
						<h1>You do not have permission to access this resource.</h1>
					</div>
					{ !inModal ? (
						<>
							<Header />
							<Footer />
						</>
					) : null }
				</>
			)
	);
};

export default CreateEditUser;
