import axios from "axios";
import useAuth from "hooks/useAuth";
import { server } from "common/app-settings";
import { useCallback } from "react";

export const API_DELETE = "DELETE";
export const API_POST = "POST";
export const API_PUT = "PUT";
export const API_GET = "GET";

const useApi = () => {
	const { customHeaders } = useAuth();
	const api = useCallback(({
		method = API_GET,
		path,
		data,
		headers,
		options
	}) => {
		const requestHeaders = {
			...customHeaders,
			...headers
		};

		switch (method) {
			case API_GET: {
				return axios.get(`${server}${path}`, { headers: requestHeaders, ...options })
					.then((response) => response.data);
			}
			case API_PUT: {
				return axios.put(`${server}${path}`, data, { headers: requestHeaders, ...options })
					.then((response) => response.data);
			}
			case API_POST: {
				return axios.post(`${server}${path}`, data, { headers: requestHeaders, ...options })
					.then((response) => response.data);
			}
			case API_DELETE: {
				return axios.delete(`${server}${path}`, { headers: requestHeaders, data, ...options })
					.then((response) => response.data);
			}
			default: throw new Error("Unknown API Method");
		}
	}, [customHeaders]);

	return api;
};

export default useApi;
